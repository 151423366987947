import React, { useState, useEffect } from 'react'
import styles from './RefinaryOperatingCost.module.scss'
import RefinaryOperatingCostGraph from './RefinaryOperatingCostGraph'
import { fetchKochiInrDataOperatingCost, fetchKochiUsdDataOperatingCost, fetchMumbaiInrDataOperatingCost, fetchMumbaiUsdDataOperatingCost, fetchBinaInrDataOperatingCost, fetchBinaUsdDataOperatingCost, fetchupdatedStatus } from '../../../services/Refinery'
import { QuarterlyDataNotFound } from '../../DataNotFound/quarterlydatanotfound'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const RefinaryOperatingCost = (props) => {
    const [operCostMumbaiUsdData, setOperCostMumbaiUsdData] = useState([])
    const [operCostMumbaiInrData, setOperCostMumbaiInrData] = useState([])
    const [operCostKochiUsdData, setOperCostKochiUsdData] = useState([])
    const [operCostKochiInrData, setOperCostKochiInrData] = useState([])
    const [operCostBinaUsdData, setOperCostBinaUsdData] = useState([])
    const [operCostBinaInrData, setOperCostBinaInrData] = useState([])
    const [graphData, setGraphData] = useState([])
    const [showingGraphUnit, setShowingGraphUnit] = useState('USD/Barrel')
    const [targetGraphUnit, setTargetGraphUnit] = useState('Rs/MT')
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [isNoDataAvailableUSD, setIsNoDataAvailableUSD] = useState(false)
    const [updatedStatus, setUpdatedStatus] = useState('')

    useEffect(() => {
        if (props.location === 'kochi') {
        fetchKochiUsdDataOperatingCost(props.year)
            .then((response) => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailableUSD(true)
                } else {
                    setOperCostKochiUsdData(response.data)
                    if (props.location === 'kochi') {
                        setGraphData(response.data)
                    }
                    setIsNoDataAvailableUSD(false)

                }

            })
            .catch((error) => console.debug(error))
        fetchKochiInrDataOperatingCost(props.year)
            .then((response) => {
                if (isEmpty(response.data)) {

                    setIsNoDataAvailable(true)
                } else {
                    setOperCostKochiInrData(response.data)

                    setIsNoDataAvailable(false)

                }

            })
            .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "Kochi Refinery" && res.kpi_name === "Operating Cost" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }
        if (props.location === 'mumbai') {
        fetchMumbaiUsdDataOperatingCost(props.year)
            .then((response) => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailableUSD(true)
                } else {
                    setOperCostMumbaiUsdData(response.data)
                    if (props.location === 'mumbai') {
                        setGraphData(response.data)
                    }
                    setIsNoDataAvailableUSD(false)

                }

            })
            .catch((error) => console.debug(error))
        fetchMumbaiInrDataOperatingCost(props.year)
            .then((response) => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailable(true)
                } else {
                    setOperCostMumbaiInrData(response.data)
                    setIsNoDataAvailable(false)

                }

            })
            .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "Mumbai Refinery" && res.kpi_name === "Operating Cost" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }
        if (props.location === 'bina') {
        fetchBinaUsdDataOperatingCost(props.year)
            .then((response) => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailableUSD(true)
                } else {
                    setOperCostBinaUsdData(response.data)
                    if (props.location === 'bina') {
                        setGraphData(response.data)
                    }
                    setIsNoDataAvailableUSD(false)

                }
            })
            .catch((error) => console.debug(error))
        fetchBinaInrDataOperatingCost(props.year)
            .then((response) => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailable(true)
                } else {
                    setOperCostBinaInrData(response.data)
                    setIsNoDataAvailable(false)

                }

            })
            .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "Bina Refinery" && res.kpi_name === "Operating Cost" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }
    }, [props.location, props.year])

    useEffect(() => {
        function switchForLocationButton() {
            if (props.location === 'mumbai') {
                setGraphData(operCostMumbaiUsdData)
                setShowingGraphUnit('USD/Barrel')
                setTargetGraphUnit('Rs/MT')
            }
            if (props.location === 'kochi') {
                setGraphData(operCostKochiUsdData)
                setShowingGraphUnit('USD/Barrel')
                setTargetGraphUnit('Rs/MT')
            }
            if (props.location === 'bina') {
                setGraphData(operCostBinaUsdData)
                setShowingGraphUnit('USD/Barrel')
                setTargetGraphUnit('Rs/MT')
            }
        }
        switchForLocationButton()
    }, [props.location, operCostMumbaiUsdData, operCostKochiUsdData, operCostBinaUsdData])

    function switchUnits(location) {
        if (location === 'mumbai') {
            if (targetGraphUnit === 'Rs/MT') {
                // console.debug("Switch USD to INR");
                // console.debug("GRM-Mumbai-INR -- ", operCostMumbaiInrData);
                setGraphData(operCostMumbaiInrData)
                setShowingGraphUnit('Rs/MT')
                setTargetGraphUnit('USD/Barrel')
            }
            if (targetGraphUnit === 'USD/Barrel') {
                // console.debug("Switch INR to USD");
                // console.debug("GRM-Mumbai-USD -- ", operCostMumbaiUsdData);
                setGraphData(operCostMumbaiUsdData)
                setShowingGraphUnit('USD/Barrel')
                setTargetGraphUnit('Rs/MT')
            }
        }
        if (location === 'kochi') {
            if (targetGraphUnit === 'Rs/MT') {
                // console.debug("Switch USD to INR");
                // console.debug("GRM-Kochi-INR -- ", operCostKochiInrData);
                setGraphData(operCostKochiInrData)
                setShowingGraphUnit('Rs/MT')
                setTargetGraphUnit('USD/Barrel')
            }
            if (targetGraphUnit === 'USD/Barrel') {
                // console.debug("Switch INR to USD");
                // console.debug("GRM-Kochi-USD -- ", operCostKochiUsdData);
                setGraphData(operCostKochiUsdData)
                setShowingGraphUnit('USD/Barrel')
                setTargetGraphUnit('Rs/MT')
            }
        }
        if (location === 'bina') {
            if (targetGraphUnit === 'Rs/MT') {
                // console.debug("Switch USD to INR");
                // console.debug("GRM-Mumbai-INR -- ", operCostMumbaiInrData);
                setGraphData(operCostBinaInrData)
                setShowingGraphUnit('Rs/MT')
                setTargetGraphUnit('USD/Barrel')
            }
            if (targetGraphUnit === 'USD/Barrel') {
                // console.debug("Switch INR to USD");
                // console.debug("GRM-Mumbai-USD -- ", operCostMumbaiUsdData);
                setGraphData(operCostBinaUsdData)
                setShowingGraphUnit('USD/Barrel')
                setTargetGraphUnit('Rs/MT')
            }
        }
    }

    return (
        <div
            className={`d-flex flex-column justify-content-between ${styles.operatingCostContainer}`}
        >
            <div className={`d-flex align-items-center ${styles.graphTitle}`}>
                <span>Operating Cost {showingGraphUnit} (YTM)-Quarterly
                {/* {props.year === '2022' ?" - LY": ""} */}
                </span>
                {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                </div>}
            </div>
            {targetGraphUnit === 'USD/Barrel' ?
                isNoDataAvailableUSD ?
                    <QuarterlyDataNotFound height={620} />
                    :
                    (isEmpty(graphData) ? <NoDataFound height={620} /> : <RefinaryOperatingCostGraph location={props.location} graphData={graphData}></RefinaryOperatingCostGraph>)
                : targetGraphUnit === 'Rs/MT' ?
                    isNoDataAvailable ?
                        <QuarterlyDataNotFound height={620} />
                        :
                        (isEmpty(graphData) ? <NoDataFound height={620} /> : <RefinaryOperatingCostGraph location={props.location} graphData={graphData}></RefinaryOperatingCostGraph>)
                    :
                    <NoDataFound height={620} />
            }
            <div className={`d-flex align-items-center justify-content-between ${styles.OperatingCostFooter}`}>
                <div onClick={() => switchUnits(props.location)} className={`${styles.footerLeft}`}>
                    <span>View in {targetGraphUnit}</span>
                </div>
                <div className={`${styles.footerRight}`}>
                    <span>
                        {/* {graphData != undefined && graphData.length > 0 ? <>Last Updated on {new Date(graphData[graphData.length - 1].createdAt).toLocaleDateString()}</> : <>No Data Avaliable</>} */}
                        {graphData != undefined && graphData.length > 0 ? '' : <>No Data Avaliable</>}
                        {/* 3<sup>rd</sup>Jan'21 */}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default RefinaryOperatingCost
