import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './GrmGraph.module.scss'
//import { inrFormat, usdFormat } from '../../../utility/utility'
import {numberFormatEnIn} from '../../../utility/utility'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

function GrmGraph(props) {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    const [lastItem, setLastItem] = useState({})
    const [latestDate, setLatestDate] = useState({})
    const [actualArray, setActualArray] = useState([])
    useEffect(() => {
        let actualArray = []
        let targetArray = []
        let targetLabelArray = []
        var xLabels;
        if (props.year >= 2023){
            xLabels = [
                'Q1',
                'Q2',
                'Q3',
                'Q4'
            ]
        } else {
            xLabels = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sept",
                "Oct",
                "Nov",
                "Dec"
            ];
    }
        let xTicks = []

        function prepareGraphData(apiGraphData) {
            // console.debug("CURRENT API DATA",apiGraphData)
            let quarterGrmData = []
            if (apiGraphData[0] && apiGraphData[1] && apiGraphData[2]) {
                quarterGrmData.push({
                    actual: (apiGraphData[0].actual + apiGraphData[1].actual + apiGraphData[2].actual) / 3,
                    target: (apiGraphData[0].target + apiGraphData[1].target + apiGraphData[2].target) / 3
                })
            }
            if (apiGraphData[3] && apiGraphData[4] && apiGraphData[5]) {
                quarterGrmData.push({
                    actual: (apiGraphData[3].actual + apiGraphData[4].actual + apiGraphData[5].actual) / 3,
                    target: (apiGraphData[3].target + apiGraphData[4].target + apiGraphData[5].target) / 3
                })
            }
            if (apiGraphData[6] && apiGraphData[7] && apiGraphData[8]) {
                quarterGrmData.push({
                    actual: (apiGraphData[6].actual + apiGraphData[7].actual + apiGraphData[8].actual) / 3,
                    target: (apiGraphData[6].target + apiGraphData[7].target + apiGraphData[8].target) / 3
                })
            }
            if (apiGraphData[9] && apiGraphData[10] && apiGraphData[11]) {
                quarterGrmData.push({
                    actual: (apiGraphData[9].actual + apiGraphData[10].actual + apiGraphData[11].actual) / 3,
                    target: (apiGraphData[9].target + apiGraphData[10].target + apiGraphData[11].target)/3
                })
            }
            const grmData = props.year >= 2023 ? quarterGrmData : apiGraphData
            grmData.forEach((item, index) => {
                actualArray.push(parseFloat(item.actual).toFixed(2))
                setActualArray(actualArray)
                targetArray.push(parseFloat(item.target).toFixed(2))
                // targetArray.push(((item.target - item.actual) < 0) ? 0 : (item.target - item.actual));
                targetLabelArray.push(item.target)
            })
            xTicks = xLabels.slice(0, apiGraphData.length)
            let lastItem = apiGraphData[apiGraphData.length - 1]
            const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            var dateStr = {}
            let dateYear = new Date(apiGraphData[apiGraphData.length - 1].entry_date).getFullYear()
            let sNumber = dateYear.toString();
            let shortYr = sNumber.slice(2, 4);
            dateStr.date = month[new Date(apiGraphData[apiGraphData.length - 1].entry_date).getMonth()] + "'" + shortYr;
            dateStr.date1 = month[new Date(apiGraphData[apiGraphData.length - 1].entry_date).getMonth()];
            const quarter = dateStr.date1 === "Mar" ? "Q4" : dateStr.date1 === "Jun" ? "Q1" : dateStr.date1 === "Sept" ? "Q2" : dateStr.date1 === "Dec" ? "Q3" : ""

            // if (props.year > 2023){
            //     setLatestDate(dateStr.date1)
            // }else{
            //     setLatestDate(quarter)
            // }
            setLatestDate(quarter)
            lastItem && setLastItem(lastItem)
        }

        prepareGraphData(props.graphData)

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let gradientMumbai = ctx.createLinearGradient(0, 0, 0, 500)
        gradientMumbai.addColorStop(1, '#1f47ac')
        gradientMumbai.addColorStop(0, '#0897c9')

        let gradientKochi = ctx.createLinearGradient(0, 0, 0, 500)
        gradientKochi.addColorStop(0, '#9150c8')
        gradientKochi.addColorStop(1, '#28104e')

        let gradientBina = ctx.createLinearGradient(0, 0, 0, 500)
        gradientBina.addColorStop(0, '#99eb6a')
        gradientBina.addColorStop(1, '#5bb048')

        let gradientGrm = ''
        let targetBackgroundColor = ''

        ////SET COLORS ACCORDING TO LOCATION
        if (props.location === 'mumbai') {
            gradientGrm = gradientMumbai
            targetBackgroundColor = 'rgb(13, 105, 172, 0.4)'
        }
        if (props.location === 'kochi') {
            gradientGrm = gradientKochi
            targetBackgroundColor = 'rgb(98, 55, 160, 0.4)'
        }
        if (props.location === 'bina') {
            gradientGrm = gradientBina
            targetBackgroundColor = 'rgb(93, 250, 2, 0.4)'
        }

        const chartConfig = {
            type: 'bar',
            data: {
                labels: xTicks,
                datasets: [
                    {
                        xAxisID: 'bar-x-axis1',
                        label: 'Actual',
                        data: actualArray,
                        categoryPercentage: 1,
                        barPercentage: 0.2,
                        backgroundColor: gradientGrm,
                        borderWidth: 0,
                        datalabels: {
                            anchor: 'end',
                            align: 'start',
                            color: '#dee5ef',
                            font: {
                                size: 14,
                            },
                        },
                    },
                    {
                        xAxisID: 'bar-x-axis2',
                        label: 'Target',
                        backgroundColor: targetBackgroundColor,
                        categoryPercentage: 0.7,
                        data: targetArray,
                        borderWidth: 0,
                        pointRadius: 0,
                        datalabels: {
                            display: false,
                            // formatter: function (value, context) {
                            //   return value > 0 ? targetLabelArray[context.dataIndex] : ""
                            // },
                            // anchor: "end",
                            // align: 'end',
                            // offset: 0,
                            // color: "rgb(161, 173, 187)",
                            // font: {
                            //   size: 20
                            // }
                        },
                    },
                ],
            },
            options: {
                // barRoundness: 1,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            type: 'category',

                            display: true,
                            offset: true,
                            id: 'bar-x-axis1',

                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                        {
                            display: false,
                            offset: true,
                            id: 'bar-x-axis2',
                            type: 'category',

                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            beginAtZero: true,
                            ticks: {
                                beginAtZero: true,
                                display: false,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            gridLines: {
                                color: 'transparent',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1,
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphData, props.location]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`flex-grow-1 ${styles.graphContainer}`}>
            <div
                className={`d-flex justify-content-between align-items-center ${styles.legendsContainer}`}
            >
                <div className={`${styles.leftLegend}`}>
                    <div className={`d-flex align-items-center mt-1 ${styles.actual}`}>
                        <span
                            className={`mr-2 ${
                                props.location === 'mumbai'? styles.actualMumbaiDot
                                :props.location === 'kochi'? styles.actualKochiDot
                                : styles.actualBinaDot
                            }`}
                        ></span>
            Actual:
                        {` ${
                            props.graphData !== undefined && props.graphData.length > 0 ? props.graphData[props.graphData.length-1].unit === 'USD/barrel'
                                ? `$${actualArray[actualArray.length -1]}`
                                : `INR ${actualArray[actualArray.length -1]}`
                                : ''
                        }`}{' '}
                        {props.graphData !== undefined && props.graphData.length > 0 ? props.graphData[props.graphData.length-1].unit === 'USD/barrel' ? ' per bbl' : 'Cr' : ''}
                        {' '}(
                        {`${
                            props.graphData !== undefined && props.graphData.length > 0 ? latestDate
                                : ''
                        }`}
                        )
                    </div>
                    <div className={`d-flex align-items-center mt-3 ${styles.target}`}>
                        <span
                            className={`mr-2 ${
                                props.location === 'mumbai'? styles.targetMumbaiDot
                                :props.location === 'kochi'? styles.targetKochiDot
                                : styles.targetBinaDot
                            }`}
                        ></span>
            Target:
                        {` ${
                            props.graphData !== undefined && props.graphData.length > 0 ? props.graphData[props.graphData.length-1].unit === 'USD/barrel'
                                ? `$${numberFormatEnIn(parseFloat(props.graphData[props.graphData.length-1].target).toFixed(2))}`
                                : `INR ${numberFormatEnIn(parseFloat(props.graphData[props.graphData.length-1].target).toFixed(2))}`
                                : ''
                        }`}{' '}
                        {props.graphData !== undefined && props.graphData.length > 0 ? props.graphData[props.graphData.length-1].unit === 'USD/barrel' ? ' per bbl' : 'Cr' : ''}
                        {' '}(
                        {`${
                            props.graphData !== undefined && props.graphData.length > 0 ? latestDate
                                : ''
                        }`}
                        )
                    </div>
                </div>
            </div>

            <div className={`${styles.grmGraph}`}>
                <canvas ref={chartContainer} />
            </div>
        </div>
    )
}
export default GrmGraph
