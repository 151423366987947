import React, { useEffect, useState } from 'react'

import styles from './RefinaryDistillateYield.module.scss'
import DistillateYieldChart from './DistillateYieldChart'
import { Spinner } from 'react-bootstrap'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { fetchDistillateYieldMumbaiMtd, fetchDistillateYieldMumbaiYtm, fetchDistillateYieldKochiYtm, fetchDistillateYieldkochiMtd, fetchDistillateYieldBinaYtm, fetchDistillateYieldBinaMtd, fetchupdatedStatus } from '../../../services/Refinery'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const RefinaryDistillateYield = (props) => {
    const [disYieldMumbaiMtdData, setDisYieldMumbaiMtdData] = useState([])
    const [disYieldKochiMtdData, setDisYieldKochiMtdData] = useState([])
    const [disYieldMumbaiYtmData, setDisYieldMumbaiYtmData] = useState([])
    const [disYieldKochiYtmData, setDisYieldKochiYtmData] = useState([])
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [disYieldBinaMtdData, setDisYieldBinaMtdData] = useState([])
    const [disYieldBinaYtmData, setDisYieldBinaYtmData] = useState([])
    const [updatedStatus, setUpdatedStatus] = useState('')

    const [ytmGraphData, setYtmGraphData] = useState(null)
    const [mtdGraphData, setMtdGraphData] = useState(null)
    // const [monthGraphUnit, setShowingGraphUnit] = useState("MTD");
    // const [yearGraphUnit, setTargetGraphUnit] = useState("YTM");

    useEffect(() => {
        function switchLocation() {
            if (props.location === 'mumbai') {
                setYtmGraphData(disYieldMumbaiYtmData)
                setMtdGraphData(disYieldMumbaiMtdData)
            }
            if (props.location === 'kochi') {
                setYtmGraphData(disYieldKochiYtmData)
                setMtdGraphData(disYieldKochiMtdData)
            }
            if (props.location === 'bina') {
                setYtmGraphData(disYieldBinaYtmData)
                setMtdGraphData(disYieldBinaMtdData)
            }
        }
        switchLocation()
    }, [
        props.location,
        disYieldMumbaiYtmData,
        disYieldMumbaiMtdData,
        disYieldKochiYtmData,
        disYieldKochiMtdData,
        disYieldBinaYtmData,
        disYieldBinaMtdData
    ])

    useEffect(() => {
        if (props.location === 'mumbai') {
            fetchDistillateYieldMumbaiMtd(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setDisYieldMumbaiMtdData(response.data)
                        setMtdGraphData(response.data)

                        setIsNoDataAvailable(false)

                    }
                })
                .catch((error) => console.debug(error))
            fetchDistillateYieldMumbaiYtm(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {

                        setDisYieldMumbaiYtmData(response.data)
                        setYtmGraphData(response.data)

                        setIsNoDataAvailable(false)
                    }
                })
                .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "Mumbai Refinery" && res.kpi_name === "Distillate Yield" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        } 
        if (props.location === 'kochi') {
            fetchDistillateYieldkochiMtd(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setDisYieldKochiMtdData(response.data)
                        setMtdGraphData(response.data)

                        setIsNoDataAvailable(false)

                    }
                })
                .catch((error) => console.debug(error))

            fetchDistillateYieldKochiYtm(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {

                        setDisYieldKochiYtmData(response.data)
                        setYtmGraphData(response.data)

                        setIsNoDataAvailable(false)
                    }
                })
                .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "Kochi Refinery" && res.kpi_name === "Distillate Yield" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }
        if (props.location === 'bina') {
            fetchDistillateYieldBinaMtd(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setDisYieldBinaMtdData(response.data)
                        setMtdGraphData(response.data)

                        setIsNoDataAvailable(false)

                    }
                })
                .catch((error) => console.debug(error))

            fetchDistillateYieldBinaYtm(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {

                        setDisYieldBinaYtmData(response.data)
                        setYtmGraphData(response.data)

                        setIsNoDataAvailable(false)
                    }
                })
                .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "Bina Refinery" && res.kpi_name === "Distillate Yield" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }

    }, [props.location, props.year])

    return (
        <div
            className={`d-flex flex-column justify-content-between ${styles.DistillateYieldContainer}`}
        >
            <div className={`d-flex align-items-center ${styles.graphTitle}`}>
                <span>Distillate Yield - Monthly</span>
                {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                </div>}
            </div>
            {isNoDataAvailable ?
                <NoDataFound height={620} />
                :
                (
                    <>
                        {ytmGraphData && mtdGraphData
                            ? (<DistillateYieldChart
                                location={props.location}
                                ytmGraphData={ytmGraphData}
                                mtdGraphData={mtdGraphData}
                            ></DistillateYieldChart>) :
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>

                        }
                        
                    </>
                )}
                <div
                            className={`d-flex align-items-center justify-content-end ${styles.grmFooter}`}
                        >
                            <div className={`${styles.footerRight}`}>
                                <span>
                                    {ytmGraphData != undefined && ytmGraphData.length > 0 ? '' : <>No Data Avaliable</>
                                    }
                                </span>
                            </div>
                        </div>
        </div>
    )
}
export default RefinaryDistillateYield
